/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        // mobile navigation button

        $(".entry-content").fitVids();
        

        $(".menu-trigger").on('click touchstart', function (e) {

          var body = $('body');
          e.preventDefault();

          if(body.hasClass('menu-open')) {

            body.removeClass('menu-open');

          } else {

            body.addClass('menu-open');

          }
          
        });

       

        // init headroom.js
        // $(".site-header").headroom();

        //AJAX stuff for blog post load more button
        var cat = $('#more_posts').data('category'); // category
        var ppp = $('#more_posts').data('ppp'); // Post per page
        var pageNumber = 1;
        var cpt = $('#more_posts').data('cpt'); // custom post type
        var tax = $('#more_posts').data('tax'); // custom post type
        var term = $('#more_posts').data('term'); // custom post type
        var order = $('#more_posts').data('order'); // custom post type
        var orderby = $('#more_posts').data('orderby'); // custom post type
        var str;
        function load_posts() {
            pageNumber++;
            if (tax) {
              str = '&order=' + order + '&orderby=' + orderby + '&cpt=' + cpt +'&cat=' + cat + '&pageNumber=' + pageNumber + '&ppp=' + ppp + '&action=more_post_ajax&tax=' + tax + '&term=' + term;
            } else {
              str = '&order=' + order + '&orderby=' + orderby + '&cpt=' + cpt +'&cat=' + cat + '&pageNumber=' + pageNumber + '&ppp=' + ppp + '&action=more_post_ajax';
            }
            $.ajax({
                type: "POST",
                dataType: "html",
                url: ajaxURL, // this is localized through setup.php
                data: str,
                success: function (data) {
                    var $data = $(data);
                    if ($data.length) {
                        $("#ajax-posts").append($data);
                        $("#more_posts").attr("disabled", false).removeClass('loading');
                    } else {
                        $("#more_posts").attr("disabled", true).removeClass('loading').addClass('no-more-posts').html('No more posts found.');
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    $loader.html(jqXHR + " :: " + textStatus + " :: " + errorThrown);
                }

            });
            return false;
        }

        $( document ).ready(function() {
            // run load_posts function on click
            $("#more_posts").on("click", function (e) {
                if (!$(this).hasClass('no-more-posts')) {
                    $("#more_posts").attr("disabled", true).addClass('loading');
                    load_posts();
                }
                e.preventDefault();
            });
          });

      },
      finalize: function() {

        // fix background-attachment bug on IE 11
        // https://coderwall.com/p/hlqqia/ie-fix-for-jumpy-fixed-bacground
        if(navigator.userAgent.match(/Trident\/7\./)) { // if IE
          $('body').on("mousewheel", function () {
              event.preventDefault(); 
              var wheelDelta = event.wheelDelta;
              var currentScrollPosition = window.pageYOffset;
              window.scrollTo(0, currentScrollPosition - wheelDelta);
              
          });
        }

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'single': {
      init: function() {
        $('.social-share-link').click(function(e) {
            e.preventDefault();
            window.open($(this).attr('href'), 'fbShareWindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
            return false;
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'image_gallery_component': {
      init: function() {

        function resizeSlider() {
            var contentHeight = $('.project-content .title').height() + 100;
            var sliderHeight = $(window).height() - contentHeight;
            $('.project-slider .item').css('height', sliderHeight);
        }
        
        resizeSlider();

        $(window).on('resize', function () {
            resizeSlider();
        });

        $('.owl-carousel').owlCarousel({
            loop:true,
            margin:10,
            nav:true,
            dots:true,
            lazyLoad: true,
            autoplay:true,
            autoplayTimeout:5000,
            autoHeight:true,
            items:1,
            animateOut: 'fadeOut',
            mouseDrag:false,
            touchDrag:false,
            URLhashListener:true,
            startPosition: 'URLHash',
            navText: ['«','»',]
        });

      }
    },
    'content_selector_component': {
      init: function() {

        var trigger = $('.selector-titles a');

        trigger.on('click', function(e) {

            var contentID = $(this).attr('href');
            trigger.removeClass('active');
            $(this).addClass('active');

            $('.selector-container .item.active').fadeOut("normal", function() {
                $(this).removeClass('active');
                $(contentID).fadeIn().addClass('active');
            });

            e.preventDefault();

          });
      }
    },
    'accordion_component': {
      init: function() {
        var acc = $('.accordion-item .heading');
        var i;
        for (i = 0; i < acc.length; i++) {
          acc[i].onclick = function() {
            this.classList.toggle("active");
            var panel = this.nextElementSibling;
            if (panel.style.maxHeight){
              panel.style.maxHeight = null;
            } else {
              panel.style.maxHeight = panel.scrollHeight + "px";
            } 
          };
        }
      }
    },
    'map_component': {
      init: function() {

        var cityTrigger = $('a.city-selector');

        cityTrigger.on('click', function(e) {

            var mapID = $(this).attr('href');
            cityTrigger.removeClass('active');
            $(this).addClass('active');

            $('.map .item.active').fadeOut("normal", function() {
                $(this).removeClass('active');
                $(mapID).fadeIn().addClass('active');
            });

            e.preventDefault();

        });
      }
    },
    'team_member_component': {
      init: function() {
        console.log('gallery init');
          $('a.trigger').featherlightGallery({
              previousIcon: '«',
              nextIcon: '»',
              galleryFadeIn: 300,
              openSpeed: 300
          });
        
      }
    },
   // 'image_gallery_component': {
   //    init: function() {
   //      console.log('gallery init');
   //        $('a.trigger').featherlightGallery({
   //            previousIcon: '«',
   //            nextIcon: '»',
   //            galleryFadeIn: 300,
   //            openSpeed: 300
   //        });
        
   //    }
   //  },
    'video_component': {
      init: function() {
        $('.play-video').on('click', function(e) {
          $('.video-frame').addClass('playing-video');
          $(".responsive-container iframe")[0].src += "&autoplay=1";
          e.preventDefault();
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
